import { getCrmProfile } from "@/utils/account-localstorage";

export const isMemberVisible = (pipeline, member) => {
  if (!member) return false;

  if (isSuperAdmin()) return true;
  if (isTeamHead(pipeline)) return true;
  if (isTeamLead(pipeline) && member.manager && member.manager.id === getCrmProfile().id) return true;

  return member.user.id === getCrmProfile().id;
};

// TODO: Currently filtered by server
export const isDealVisible = (pipeline, deal) => {
  if (!deal) return false;

  if (isSuperAdmin()) return true;
  if (isTeamHead(pipeline)) return true;
  if (isTeamLead(pipeline)) return true;

  return deal.user_id === getCrmProfile().id;
};

// TODO: Currently filtered by server
export const isDealEditable = (pipeline, deal) => {
  if (!deal) return false;

  if (isSuperAdmin()) return true;
  if (isTeamHead(pipeline)) return true;
  if (isTeamLead(pipeline)) return true;

  return deal.user_id === getCrmProfile().id;
};

export const isDealSuperEditable = (pipeline, deal) => {
  if (!deal) return false;

  if (isTeamHead(pipeline)) return true;
  if (isTeamLead(pipeline, deal)) return true;

  if (isSuperAdmin()) return true;

  return false;
};

export const isSuperAdmin = () => {
  if (getCrmProfile().role_id === 1) return true;

  return false;
}

export const isTeamHead = (pipeline) => {
  let result = false;

  pipeline.members.forEach(m => {
    if (m.role === 'TEAM_HEAD' && m.user.id === getCrmProfile().id) result = true;
  })

  return result;
}

export const isTeamLead = (pipeline) => {
  let result = false;

  pipeline.members.forEach(m => {
    if (m.role === 'TEAM_LEAD' && m.user.id === getCrmProfile().id) result = true;
  })

  return result;
}

